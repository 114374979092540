export const LIST_HEADER_TABLE_HISTORIC = [
  {
    name: 'Nome',
    class: 'name',
  },
  {
    name: 'Data',
    class: 'date',
  },
  {
    name: 'Valor pago',
    class: 'value',
  },
  {
    name: 'Forma de pagamento',
    class: 'methods',
  },
  {
    name: 'Recibo',
    class: 'receipt',
  },
];
